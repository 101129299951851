body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./docs/images/mountainpic-background.png")
  /* background: #1F2833;  fallback for old browsers */
  /* background: -webkit-linear-gradient(to top, #0B0C10, #303d4e);  Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(to top, #0B0C10, #303d4e); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.particles canvas {
  display: block;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition: opacity .8s ease, -webkit-transform 1.4s ease;
  transition: opacity .8s ease, transform 1.4s ease;
}

.particles {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0
}

.fab, .far, .fas, .fa {
  font-family: FontAwesome !important;
 }

 a {
   text-decoration: none;
 }
