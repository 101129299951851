/* ============================================= */
/* ============== Animation CSS ================*/

/* Underline From Center */
.hvr-underline-from-center {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  background: #66FCF1;
  height: 4px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-underline-from-center:hover:before, .hvr-underline-from-center:focus:before, .hvr-underline-from-center:active:before {
  left: 0;
  right: 0;
}

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* Wobble Skew */
@-webkit-keyframes hvr-wobble-skew {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg);
  }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg);
  }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg);
  }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg);
  }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0);
  }
}
@keyframes hvr-wobble-skew {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg);
  }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg);
  }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg);
  }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg);
  }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0);
  }
}
.hvr-wobble-skew {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-wobble-skew:hover, .hvr-wobble-skew:focus, .hvr-wobble-skew:active {
  -webkit-animation-name: hvr-wobble-skew;
  animation-name: hvr-wobble-skew;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* ============================================= */

.nav-text .mdl-navigation__link {
  font-family: 'Francois One', sans-serif;
  font-size: 20px !important;
  color: #66FCF1 !important;
}

.nav-text {
  position: absolute;
  right: 0;
  margin-right: 5em !important;
}

.nav-header {
  background-color: rgb(24, 28, 43) !important;
}

.home-link .mdl-navigation__link{
  font-family: 'Francois One', sans-serif;
  font-size: 20px !important;
  color: #66FCF1 !important;
}

.profile-pic {
  height: 250px;
  border-radius: 20em;
  padding: 20px;
  display: block;
  margin: auto;
  position: relative;
  z-index: 1;
}

.text-container {
  background-color: black;
  opacity: 0.8;
  width: 75%;
  margin: auto;
  border-radius: 10px;
  z-index: 1;
}

.text-container h1 {
  /* font-size: 66px; */
  text-align: center;
  font-weight: bold;
  color: #66FCF1;
}

.text-container p {
  font-size: 20px;
  margin: 10px;
  text-align: center;
  color: #dedfe0;
}

.social-links {
  display: flex;
  justify-content: space-around;
  padding: 2em;
  width: 50%;
  margin: 0 auto;
}

.social-links span{
  color: #dedfe0;
  font-size: 5em;
}

.resume {
  opacity: 1;
  margin: auto;
  display: block;
  width: 50%;
  height: 1800px;
}

@media only screen and (max-width: 600px) {
  .resume {
    height: 450px;
  }
}
@media screen and (max-width: 1024px){
  .mdl-layout__drawer-button {
    color: rgb(60, 176, 243) !important;
  }
}


.resume-container {
  margin: auto;
  border-radius: 10px;
  z-index: 1;
  position: relative;
}

.react-pdf__Document {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.resume-container h1{
  font-size: 66px;
  text-align: center;
  font-weight: bold;
  color: #66FCF1;
}

.contact-me-textfields {
  padding: 25px;
}

.contact-me-textfields p {
  font-size: 20px;
  margin: 10px;
  text-align: center;
  color: black;
}

.mdl-card {
  border-radius: 20px !important;
}

.about-me-text p {
  text-align: left !important;
}

.about-me-text {
  padding: 3em;
}

.about-me-text a {
  color: #66FCF1;
}

.mdl-tabs__tab {
  color: #66FCF1 !important;
}

.iframe-container {
  padding-bottom:56.25%; 
  position:relative; 
  display:block; 
  width: 100%;
}

#resume-iframe {
  position:absolute; 
  top:0; 
  left: 0;
}
